<template>
  <div>
    <div class="filters mb-8" v-if="allowEdit">
      <div class="filters__item">
        <f-master-select
          v-model="filters.main"
          optional
          :isMaster="$store.state.user.isMaster"
          :countries="$store.state.country.list"
          :userMasters="$store.state.user.masters"
          :is-multiple="false"
          :select-attrs="{
            outlined: false,
            counter: false,
            solo: true,
            hideDetails: true,
          }"
        />
      </div>
    </div>
    <v-row>
      <v-col cols="12">
        <div class="kanban-list">
          <div
            class="kanban-list-item"
            v-for="(item, index) in filteredList"
            :key="index"
          >
            <f-learningPaths-list-item
              :learningPath="item"
              :is-admin="allowEdit"
              @view="view"
              @report="report"
              @edit="edit"
              @remove="remove"
              @changeStatus="changeStatus"
            />
          </div>
        </div>
      </v-col>
      <v-col v-if="!allowEdit && filteredList.length === 0">
        <p>{{ $t('labels.noLearningPaths') }}</p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import LearningPathsListItem from './LearningPathsListItem.vue';
import MasterSelect from '../../components/shared/MasterSelect.vue';
import { UserType } from '../../helpers/user_utils';

import FList from '../../components/LearningPaths/List.vue';

export default {
  components: {
    'f-learningPaths-list-item': LearningPathsListItem,
    'f-master-select': MasterSelect,
    FList,
  },
  data: () => ({
    list: [],
    flags: {
      loaded: false,
    },
    filters: {
      main: null,
    },
    learningPaths: {},
  }),
  computed: {
    allowEdit() {
      return (
        this.$store.state.auth.user.type == UserType.Admin ||
        this.$store.state.auth.user.type == UserType.TSM
      );
    },
    filteredList() {
      return this.list.filter(item => {
        if (this.filters.main) {
          return item.masters[0]._id === this.filters.main;
        }
        return true;
      });
    },
    filterLabel() {
      return this.$i18n.t('labels.filterMaster');
    },
    title() {
      return this.$t('labels.learningPaths');
    },
  },
  async created() {
    this.$store.dispatch('country/loadList');
    this.get();
  },
  methods: {
    async get() {
      this.$store.commit('system/loading');
      const result = await this.$store.dispatch('learningPaths/loadList');
      this.$store.commit('system/loading', false);
      this.list = result;
      this.flags.loaded = true;
    },
    create() {
      this.$router.push({
        name: 'learning-paths-create',
      });
    },
    report(id) {
      this.$router.push({
        name: 'learning-paths-reports',
        params: { id },
      });
    },
    view(id) {
      this.$router.push({
        name: 'learning-paths-view',
        params: { id },
      });
    },
    edit(id) {
      this.$router.push({
        name: 'learning-paths-edit',
        params: { id },
      });

      this.$gtm.trackEvent({
        category: 'learning-paths-admin-actions',
        event: 'click',
        action: 'learning-paths-click',
        label: 'learning-paths-edit',
        value: `edit-${id}`,
      });
    },
    async changeStatus(id, status) {
      const result = await this.$store.dispatch('learningPaths/update', {
        _id: id,
        field: 'status',
        value: status,
      });

      this.$gtm.trackEvent({
        category: 'learning-paths-admin-actions',
        event: 'click',
        action: 'learning-paths-click',
        label: 'change-status',
        value: `${id}-${status}`,
      });
    },
    async remove(id) {
      var response = await this.$store.dispatch('dialog/show', {
        title: this.$t('alerts.learningPaths.remove.title'),
        content: this.$t('alerts.learningPaths.remove.content'),
      });
      if (response) {
        await this.$store.dispatch('learningPaths/remove', id);
        this.get();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card-plus {
  height: 100%;
  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
  }
  &__link {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    i {
      color: rgba(0, 0, 0, 0.25);
      font-size: 180px;
    }
  }
}
.filters {
  &__item {
    max-width: 300px;
    display: inline-block;
    border: 1px solid #dde1e4;
  }
}
</style>
