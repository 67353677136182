<template>
  <div>
    <f-page-header :title="title" :prevLinks="[]">
      <template #actions>
        <div>
          <lms-page-header-action-btn
            icon="mdi-plus"
            @click="create"
            v-allowed="[userTypes.ADMIN]"
          >
            {{ $tt.capitalize($t('labels.create')) }}
          </lms-page-header-action-btn>
        </div>
      </template>
    </f-page-header>

    <f-container topBottom>
      <f-list />
    </f-container>
  </div>
</template>

<script>
import FList from '../../components/LearningPaths/List.vue';

export default {
  components: {
    FList,
  },
  computed: {
    title() {
      return this.$t('labels.learningPaths');
    },
  },
  async created() {
    this.get();
  },
  methods: {
    async get() {
      await this.$store.dispatch('learningPaths/loadList');
    },
    create() {
      this.$router.push({
        name: 'learning-paths-create',
      });

      this.$gtm.trackEvent({
        category: 'learning-paths-admin-actions',
        event: 'click',
        action: 'learning-paths-click',
        label: 'create-learning-paths',
        value: 'new',
      });
    },
  },
};
</script>
