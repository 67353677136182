<template>
  <lms-card
    class="
          learningPath-list-item
          d-flex
          flex-column flex-grow
          transition-swing
        "
  >
    <lms-card-title small @click="$emit('view', learningPath._id)">
      {{ title }}
    </lms-card-title>

    <v-card-text
      class="learningPath-list-item__content grow"
      @click="$emit('view', learningPath._id)"
    >
      <div
        v-html="description"
        class="learningPath-list-item__content__text"
      ></div>
    </v-card-text>
    <div v-allowed="[userTypes.ADMIN]" class="admin-actions">
      <v-card-actions class="justify-space-between">
        <div>
          <learningPath-status
            :status="learningPath.status"
            @change="status => $emit('changeStatus', learningPath._id, status)"
          />
        </div>
        <div class="btns">
          <a @click.stop="$emit('edit', learningPath._id)">
            <v-icon>mdi-pencil-outline</v-icon>
          </a>
          <!-- <a @click.stop="$emit('report', learningPath._id)">
                <v-icon>mdi-chart-box-outline</v-icon>
              </a> -->
          <a @click.stop="$emit('remove', learningPath._id)">
            <v-icon>mdi-delete-outline</v-icon>
          </a>
        </div>
      </v-card-actions>
    </div>
  </lms-card>
</template>

<script>
import strings from '../../helpers/strings';
import LearningPathStatus from './learningPathsStatus.vue';

export default {
  components: {
    LearningPathStatus,
  },
  props: {
    learningPath: {
      type: Object,
      required: true,
    },
    isAdmin: {
      type: Boolean,
    },
  },
  computed: {
    title() {
      return this.learningPath.title[this.currentLanguage];
    },
    description() {
      return strings.truncate(
        this.learningPath.description[this.currentLanguage] || '',
        255
      );
    },
    languages() {
      return this.learningPath.langs;
    },
    currentLanguage() {
      return this.languages.includes(this.$i18n.locale)
        ? this.$i18n.locale
        : this.languages[0];
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/base/variables';

.learningPath-list-item {
  height: 100%;
  &__title {
    background-color: $box-bg;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    min-height: 88px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 0;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    &::after {
      pointer-events: none;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      content: '';
      background: $box-selected;
    }
    &__wrapper {
      position: relative;
      z-index: 2;
      padding: 20px 25px;
      background: $box-selected;
      width: 100%;
      line-height: 1.2;
      height: 88px;
    }
    &__languages {
      display: flex;
      margin: 0 0 10px 0;
      &__item {
        color: #ffc007;
        margin: 0 5px 0 0;
        text-transform: uppercase;
        font-size: 12px;
      }
    }
    &__text {
      font-size: 16px;
      color: white;
    }
    &__progress {
      position: absolute;
      z-index: 2;
      top: 25px;
      left: 25px;
      font-size: 14px;
      &__value {
        color: white;
        font-weight: 900;
      }
    }
  }
  &__content {
    padding: 25px;
    cursor: pointer;
    &__text {
      font-size: 14px;
    }
  }
}
.admin-actions {
  padding: 24px 20px 15px 20px;
  border-top: 1px solid $global-bg;

  hr {
    margin-bottom: 10px;
    border-color: #eeeeee;
  }
  a {
    opacity: 0.6;
    margin: 0 0 0 15px;
  }
  i.v-icon.v-icon {
    color: $default-color;
    &:hover {
      color: $primary;
    }
  }
  .rating-title {
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    align-items: center !important;
  }
  .btns {
    margin-top: -18px;
  }
}
</style>
