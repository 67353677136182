import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.allowEdit)?_c('div',{staticClass:"filters mb-8"},[_c('div',{staticClass:"filters__item"},[_c('f-master-select',{attrs:{"optional":"","isMaster":_vm.$store.state.user.isMaster,"countries":_vm.$store.state.country.list,"userMasters":_vm.$store.state.user.masters,"is-multiple":false,"select-attrs":{
          outlined: false,
          counter: false,
          solo: true,
          hideDetails: true,
        }},model:{value:(_vm.filters.main),callback:function ($$v) {_vm.$set(_vm.filters, "main", $$v)},expression:"filters.main"}})],1)]):_vm._e(),_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('div',{staticClass:"kanban-list"},_vm._l((_vm.filteredList),function(item,index){return _c('div',{key:index,staticClass:"kanban-list-item"},[_c('f-learningPaths-list-item',{attrs:{"learningPath":item,"is-admin":_vm.allowEdit},on:{"view":_vm.view,"report":_vm.report,"edit":_vm.edit,"remove":_vm.remove,"changeStatus":_vm.changeStatus}})],1)}),0)]),(!_vm.allowEdit && _vm.filteredList.length === 0)?_c(VCol,[_c('p',[_vm._v(_vm._s(_vm.$t('labels.noLearningPaths')))])]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }